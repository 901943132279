class Evento {
    constructor() {
        this.explicitType = "evento";
        this.id = 0;
        this.titulo = "";
        this.tipo = "";
        this.status = "";
        this.repete = false;
        this.diaSemana = "";
        this.frequencia = "";
        this.valor = "";
        this.ehPago = "";
        this.dataInicio = "";
        this.dataFim = "";
        this.horaInicio = "";
        this.horaFim = "";
    }
}

export default Evento;