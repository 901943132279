

class Ministerio {
    constructor() {
        this.explicitType = "ministerio";
        this.id = 0;
        this.nome = "";
        this.descricao = "";
    }
}

export default Ministerio;