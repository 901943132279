class MinisterioMembro {
    constructor() {
        this.explicitType = "ministerioMembro";
        this.id = 0;
        this.chEsMembro = "";
        this.chEsMinisterio = "";
        this.checked = false;
    }
}

export default MinisterioMembro;