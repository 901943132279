class Evento {
    constructor() {
        this.explicitType = "inscricao";
        this.id = 0;
        this.nome = "";
        this.email = "";
        this.celular = "";
        this.chEsEvento = 0;
        this.pago = false;
    }
}

export default Evento;