import React from 'react';
import { Box } from '@mui/material';

function Ministry() {
  return (
    <Box>
      Ministério
    </Box>
  );
}

export default Ministry;