class Item {
    constructor() {
        this.explicitType = "item";
        this.id = 0;
        this.title = "";
        this.active = "";
        this.cost = "";
        this.dateDelivery = "";
        this.image = "";
    }
}

export default Item;