class Contato {
    constructor() {
        this.explicitType = "contato";
        this.id = 0;
        this.email = "";
        this.telefone = "";
        this.celular = "";
    }
}

export default Contato;