class Igreja {
    constructor() {
        this.explicitType = "Igreja";
        this.id = 0;
        this.ehBatizado = "";
        this.dataBatismo = "";
        this.igrejaBatizado = "";
        this.ultimoPastor = "";
        this.ultimaIgreja = "";
    }
}

export default Igreja;