class Parentes {
    constructor() {
        this.id = 0;
        this.chEsConjuge = "";
        this.conjuge = "";
        this.chEsPai = "";
        this.nomePai = "";
        this.chEsMae = "";
        this.nomeMae = "";
        this.filhos = [];
    }
}

export default Parentes;