class Endereco {
    constructor() {
        this.explicitType = "endereco";
        this.id = 0;
        this.cep = null;
        this.cidade = null;
        this.uf = null;
        this.logradouro = null;
        this.complemento = null;
    }
}

export default Endereco;